import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  :root{
    --green001: #086835;
    --gray001: #F6F5FA;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans;
  }
  jdiv{
    @media only screen and (max-width:480px){  
    display:none !important;
    }
  }
  body{
    font-family: 'Montserrat';
    background-color: #f0f0f0;

    @media only screen and (max-width: 768px) {
      background-color: #ffffff;
    }
    }

    .fc-day-number {
      font-size: 1.5em;
      color: #5c005c;
    }

    .fc-day-header {
      font-size: 1.5em;
      color: #00b294;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: #E9E9E9;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }

    @media (max-width: 1080px) {
      ::-webkit-scrollbar {
      width: 4px;
    }
    }

    @media (max-width: 720px) {
      ::-webkit-scrollbar {
        width: 2px;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number]{
      -moz-appearance: textfield;
    }
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

    /* animations ================================================= */
    @keyframes rendering {
      from{
        width: 0
      }
      to{
      width: 100%
      }
  }
`;
